import axios from 'axios';
import eachElement from 'Utils/eachElement.js';
import errorHandler from 'Utils/errorHandler.js';

const setUpDropInDemoRegistration = () => {
	eachElement('form.drop-in-demo-registration', form => {
		/* Get API host */
		const host = form.dataset.host || 'http://localhost:1235';

		/* Get webinar ID */
		const urlParameters = new URLSearchParams(window.location.search);
		const webinarId = form.dataset.id || urlParameters.get('wid');
		const dateSelect = form.querySelector('select[name="DateTime"]');

		/* Get country codes for form */
		axios.get(`${host}/v1/zoom/countrycodes`)
			.then(response => {
				const sortedCountries = [];

				/* Convert object to array for sorting */
				for (const code in response.data) {
					if (Object.prototype.hasOwnProperty.call(response.data, code)) {
						sortedCountries.push([code, response.data[code]]);
					}
				}

				/* Sort countries alphabetically by label */
				sortedCountries.sort((a, b) => a[1].localeCompare(b[1]));

				/* Add to select */
				const select = form.querySelector('select[name="Country"]');

				for (const country of sortedCountries) {
					const option = document.createElement('option');
					option.value = country[0];
					option.text = country[1];
					select.append(option);
				}
			}).catch(errorHandler);

		/* Get list of occurences for the form */
		axios.get(`${host}/v1/zoom/webinar/${webinarId}`)
			.then(response => {
				const occurrences = response.data.occurrences;
				const allowedStatus = new Set(['available']);
				const allowedOccurrences = occurrences.filter(occurrence => allowedStatus.has(occurrence.status));

				if (occurrences && occurrences.length > 0) {
					for (const occurrence of allowedOccurrences) {
						const option = document.createElement('option');
						option.value = occurrence.occurrence_id;

						const date = new Date(occurrence.start_time);
						option.text = date.toLocaleString('en-US', { month: 'short', day: 'numeric', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true });

						dateSelect.append(option);
					}
				}
			})
			.catch(errorHandler);


		/* Add topics to dropdown list */
		const dropInTopics = {
			deploy: 'Compare & Deploy',
			automate: 'Automation & CI/CD',
			'code-analysis': 'Code analysis (Clayton from �Ӱ���Ƶ)',
			cpq: 'Salesforce Revenue Cloud',
			'salesforce-industries': 'Salesforce Industries',
			'sandbox-seeding': 'Sandbox seeding',
			backup: 'Backup & Restore',
			archiving: 'Archiving',
		};

		const topics = form.querySelector('.topics');

		for (const [topicKey, topic] of Object.entries(dropInTopics)) {
			const checkboxWrapper = document.createElement('div');

			/* Create checkbox */
			const checkbox = document.createElement('input');
			checkbox.type = 'checkbox';
			checkbox.value = topic;
			checkbox.name = 'interest';
			checkbox.setAttribute('id', `drop-in-topic-${topicKey}`);
			checkbox.classList.add('mr-3');

			/* Check the checkbox, if desired */
			if (window.dropInTopic === topicKey) {
				checkbox.checked = true;
			}

			checkboxWrapper.append(checkbox);

			/* Create checkbox label */
			const checkboxLabel = document.createElement('label');
			checkboxLabel.innerHTML = topic;
			checkboxLabel.setAttribute('for', `drop-in-topic-${topicKey}`);

			checkboxWrapper.append(checkboxLabel);
			topics.append(checkboxWrapper);
		}

		/* Handle submission */
		form.addEventListener('submit', event => {
			event.preventDefault();
			form.querySelector('button').disabled = true;

			const data = new FormData(event.target);

			const formJson = Object.fromEntries(Array.from(data.keys(), key => {
				const value = data.getAll(key);
				return [key, value.length > 1 ? value : value.pop()];
			}));

			formJson.WebinarId = webinarId;
			formJson.State = 'na';
			formJson.OccurrenceIds = dateSelect.value;

			/* Check if no checkboxes are selected */
			if (!formJson.interest || formJson.interest.length === 0) {
				const banner = form.querySelector('.notification.is-red');
				banner.innerHTML = 'Please select at least one topic';
				banner.classList.remove('is-hidden');

				form.querySelector('button').disabled = false; /* Re-enable the button */
				return false;
			}

			formJson.CustomQuestions = [
				{
					title: 'What topic are you interested in?',
					value: Array.isArray(formJson.interest) ? formJson.interest.join(', ') : formJson.interest,
				},
				{
					title: 'What problem are you trying to overcome?',
					value: formJson.Problems,
				},
				{
					title: 'What is the size of your team?',
					value: formJson.TeamSize,
				},
			];

			axios.post(`${host}/v1/zoom/webinar/${webinarId}/register`, formJson)
				.then(() => {
					const banner = form.querySelector('.notification.is-green');
					banner.innerHTML = 'Thank you for registering for this webinar. <br/> A confirmation email is on its way. See you there!';
					banner.classList.remove('is-hidden');

					if (form.querySelector('.notification.is-red')) {
						form.querySelector('.notification.is-red').classList.add('is-hidden');
					}

					form.querySelector('.columns').classList.add('is-hidden');
				})
				.catch(error => {
					const banner = form.querySelector('.notification.is-red');
					banner.textContent = 'Something went wrong. Please check the form and try again.';
					banner.classList.remove('is-hidden');

					form.querySelector('button').disabled = false;

					errorHandler(error);
				});

			return false;
		});
	});
};

document.addEventListener('DOMContentLoaded', () => {
	setUpDropInDemoRegistration();
});
