import eachElement from 'Utils/eachElement.js';

class Tabs {
	constructor(container) {
		this.container = container;
		this.tabs = this.container.querySelectorAll('nav li');

		if (this.tabs.length === 0) {
			return;
		}

		this.autoSwitchInterval = null;
		this.speed = 10000;

		/* Determine the initial tab */
		this.currentTab = this.getInitialTab();

		for (const tab of this.tabs) {
			/* Check all tabs have corresponding content */
			if (!this.container.querySelector(`#${tab.dataset.target}`)) {
				console.warn(`Tab ${tab.dataset.target} does not have a corresponding content panel`);
			}

			tab.addEventListener('click', event => {
				event.preventDefault();
				this.switchToTab(event.target?.closest('li')?.dataset.target);
				return false;
			});
		}

		if (this.container.classList.contains('is-auto')) {
			this.startAutoSwitching();
		}

		/* Initial activation of the current tab (either from the query parameter or the first tab) */
		this.switchToTab(this.currentTab);
	}

	/* Function to get the initial tab from the query parameter */
	getInitialTab() {
		/* Get the query parameter */
		const urlParameters = new URLSearchParams(window.location.search);
		const targetTab = urlParameters.get('tab');
		const modalContent = document.querySelector('.modal-content > div');

		/* If there is a tab matching the url search parameter, add a class to reveal the tab content. This is only relevant on mobile since the tab content is hidden on load */
		if (targetTab && modalContent.querySelector(`[data-target="${targetTab}"]`)) {
			modalContent.querySelector('aside').classList.add('is-concealed');
		}

		/* Check if the tab with the value from the query parameter exists */
		const specificTab = [...this.tabs].find(tab => tab.dataset.target === targetTab);

		/* Select logged-in tab for logged-in users, otherwise default to the first tab */
		const loggedInTab = [...this.tabs].find(tab => tab.classList.contains('is-active-for-user-logged-in'));
		const isLoggedIn = document.documentElement.classList.contains('is-user-logged-in');
		const fallbackTab = (loggedInTab?.dataset?.target && isLoggedIn) ? loggedInTab.dataset.target : this.tabs[0].dataset.target;

		/* Return the specific tab if it exists, otherwise return the fallback */
		return specificTab ? specificTab.dataset.target : fallbackTab;
	}

	/* Show tab content by ID */
	showContent(tabId) {
		/* Deactivate all other tab contents */
		for (const item of this.tabs) {
			this.container.querySelector(`#${item.dataset.target}`)?.classList.add('is-hidden');
		}

		/* Activate tab content */
		this.container.querySelector(`#${tabId}`)?.classList.remove('is-hidden');
	}

	/* Switch to tab by ID */
	switchToTab(tabId) {
		this.currentTab = tabId;

		/* Deactivate all other tabs */
		const allActives = this.container.querySelectorAll('nav .is-active');
		for (const item of allActives) {
			item.classList.remove('is-active');
		}

		/* Activate tab */
		const newTab = this.container.querySelector(`nav [data-target="${tabId}"]`);
		newTab?.classList.add('is-active');
		newTab?.querySelector('.button')?.classList.add('is-active');

		/* Activate corresponding content */
		this.showContent(tabId);

		/* Update the URL to remove the ?tab=... parameter */
		const url = new URL(window.location);

		if (url.searchParams.has('tab')) {
			url.searchParams.delete('tab');
			history.replaceState({}, '', url);
		}
	}

	/* Initiate auto mode */
	startAutoSwitching() {
		clearInterval(this.autoSwitchInterval);

		this.autoSwitchInterval = setInterval(() => {
			const nextSibling = this.container.querySelector(`nav [data-target="${this.currentTab}"]`)?.nextElementSibling;

			if (nextSibling) {
				this.switchToTab(nextSibling.dataset.target);
			} else {
				this.switchToTab(this.tabs[0].dataset.target);
			}
		}, this.speed);
	}
}

document.addEventListener('DOMContentLoaded', () => {
	eachElement('.tabs', instance => {
		new Tabs(instance);
	});
});
